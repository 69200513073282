/**
 * Get the nonce from an element in the current window.
 *
 * @param window A reference to the window.
 * @param tag The tag of the element.
 */
export const getNonce = (window: Window, tag: ('script' | 'style')): string | undefined => {
  if (!window || !window.document) {
    return undefined;
  }

  const els = window.document.getElementsByTagName(tag);

  // HTMLCollectionOf<Element> is not iterable, so we need to use a for loop.
  // eslint-disable-next-line @typescript-eslint/prefer-for-of
  for (let i = 0; i < els.length; i++) {
    const nonce = els[i].getAttribute('nonce');
    if (nonce) {
      return nonce;
    }
  }
  return undefined;
};
